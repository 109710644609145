{
  "type": "FeatureCollection",
  "id": "overpasses",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "label": "8",
        "id": "overpass8",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.974471, -37.852853],
            [144.975097, -37.853],
            [144.975145, -37.852848],
            [144.97452, -37.852698],
            [144.974471, -37.852853]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "7",
        "id": "overpass7",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.976667, -37.853932],
            [144.976794, -37.853896],
            [144.976657, -37.853592],
            [144.976527, -37.853628],
            [144.976667, -37.853932]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "6",
        "id": "overpass6",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.978177, -37.851101],
            [144.977643, -37.85121],
            [144.977673, -37.8513],
            [144.978207, -37.851194],
            [144.978177, -37.851101]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "5",
        "id": "overpass5",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.971073, -37.846761],
            [144.971157, -37.846853],
            [144.971597, -37.846598],
            [144.971517, -37.846501],
            [144.971073, -37.846761]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "4",
        "id": "overpass4",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.970915, -37.846587],
            [144.971, -37.846679],
            [144.971439, -37.846424],
            [144.97136, -37.846328],
            [144.970915, -37.846587]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "3",
        "id": "overpass3",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.966357, -37.838765],
            [144.966184, -37.838478],
            [144.966031, -37.83853],
            [144.966208, -37.838821],
            [144.966357, -37.838765]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "2",
        "id": "overpass2",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.963159, -37.8442],
            [144.963283, -37.844347],
            [144.96374, -37.844085],
            [144.963611, -37.843945],
            [144.963159, -37.8442]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "1",
        "id": "overpass1",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.96686, -37.848774],
            [144.967002, -37.848896],
            [144.96741, -37.848595],
            [144.967261, -37.848472],
            [144.96686, -37.848774]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "",
        "id": "overpass9",
        "style": "overpass"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.976607, -37.846482],
            [144.976649, -37.846596],
            [144.97705, -37.846509],
            [144.977014, -37.846399],
            [144.976607, -37.846482]
          ]
        ]
      }
    }
  ]
}
