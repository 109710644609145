{
  "bar": {
    "fillColor": "#792657"
  },
  "gate": {
    "fillColor": "#BF3310",
    "fillOpacity": 1.0,
    "strokeWeight": 0.0
  },
  "grandstand": {
    "fillColor": "#4F89CB",
    "fillOpacity": 1.0,
    "strokeColor": "#4275AC",
    "strokeWeight": 1.5
  },
  "lake": {
    "fillColor": "#60A1E9",
    "fillOpacity": 1.0,
    "strokeColor": "#985853",
    "strokeOpacity": 1.0,
    "strokeWeight": 1.5
  },
  "overpass": {
    "fillColor": "#FFFFFF",
    "fillOpacity": 1.0,
    "strokeColor": "#BCBCBC",
    "strokeOpacity": 1.0,
    "strokeWeight": 1.5
  },
  "park": {
    "fillColor": "#97C05F",
    "fillOpacity": 1.0
  },
  "racetrack": {
    "strokeColor": "#000000",
    "strokeWeight": 5.0
  },
  "stage": {
    "fillColor": "#4B813C"
  },
  "turn": {
    "markerSymbol": "circle",
    "markerColor": "#EFC926",
    "markerOpacity": 1.0,
    "markerSize": 10
  },
  "viewingMound": {
    "fillColor": "#FFFFFF",
    "fillOpacity": 0.5,
    "strokeWeight": 0.0
  }
}
