{
  "type": "Feature",
  "properties": {
    "id": "park",
    "label": "Albert Park",
    "style": "park"
  },
  "geometry": {
    "type": "MultiPolygon",
    "coordinates": [
      [
        [
          [144.9689421, -37.8371563],
          [144.9692968, -37.8369763],
          [144.9695101, -37.8368587],
          [144.969801100000012, -37.8366728],
          [144.9701136, -37.8364578],
          [144.9704763, -37.836184],
          [144.9706802, -37.8360119],
          [144.9709296, -37.8357614],
          [144.9711476, -37.835566],
          [144.9712079, -37.8355321],
          [144.971253499999989, -37.8355141],
          [144.9713011, -37.8355268],
          [144.9715822, -37.8356623],
          [144.9717698, -37.8357492],
          [144.9719495, -37.8357964],
          [144.971985499999988, -37.8358121],
          [144.9724575, -37.8360491],
          [144.9727191, -37.8362216],
          [144.972874499999989, -37.8363564],
          [144.9729466, -37.8364451],
          [144.973139800000013, -37.8366988],
          [144.973233599999986, -37.836899],
          [144.9733154, -37.8371341],
          [144.9734281, -37.8373999],
          [144.9736816, -37.8380174],
          [144.973827699999987, -37.838404],
          [144.9741134, -37.839192],
          [144.9767501, -37.8465638],
          [144.9775694, -37.8487128],
          [144.97768880000001, -37.8490761],
          [144.977757200000013, -37.849345],
          [144.9778967, -37.8499349],
          [144.9790084, -37.8532886],
          [144.9791239, -37.8536079],
          [144.9792163, -37.8537884],
          [144.9793196, -37.8539472],
          [144.9794148, -37.8540838],
          [144.97948310000001, -37.8541558],
          [144.9797125, -37.8543835],
          [144.9798721, -37.854537],
          [144.9799658, -37.8545987],
          [144.980262399999987, -37.8547986],
          [144.9803978, -37.8548843],
          [144.9805266, -37.8549701],
          [144.9808323, -37.8551406],
          [144.9812092, -37.8553301],
          [144.9819568, -37.8556297],
          [144.982069700000011, -37.8556832],
          [144.982162499999987, -37.8557467],
          [144.9822399, -37.8558217],
          [144.982244799999989, -37.8558264],
          [144.9822888, -37.8559147],
          [144.9822962, -37.8559882],
          [144.9823211, -37.8560557],
          [144.982332, -37.8561227],
          [144.982365, -37.8563927],
          [144.9823707, -37.8564064],
          [144.98240340000001, -37.8564213],
          [144.982401399999986, -37.8564642],
          [144.9823994, -37.8565039],
          [144.982388, -37.8565436],
          [144.982344399999988, -37.8565833],
          [144.9823203, -37.8565934],
          [144.9822392, -37.8566479],
          [144.980366299999986, -37.8577629],
          [144.9800035, -37.8579688],
          [144.9798238, -37.8580811],
          [144.978728100000012, -37.8587312],
          [144.9781039, -37.8581123],
          [144.9780872, -37.858095],
          [144.9774595, -37.8574426],
          [144.9768412, -37.8578163],
          [144.976006500000011, -37.8573855],
          [144.9755687, -37.8571484],
          [144.9754915, -37.857189],
          [144.9744014, -37.8562877],
          [144.9735174, -37.8555287],
          [144.9725941, -37.8548607],
          [144.9709425, -37.8536176],
          [144.968067499999989, -37.8516037],
          [144.967280499999987, -37.8510034],
          [144.9663952, -37.8502729],
          [144.965706, -37.8496564],
          [144.965775799999989, -37.8496098],
          [144.965584, -37.8494488],
          [144.9655223, -37.8494891],
          [144.965080199999989, -37.8490769],
          [144.9643163, -37.8483449],
          [144.9637155, -37.847701],
          [144.963089, -37.8469894],
          [144.962565399999988, -37.8463591],
          [144.9620161, -37.8456474],
          [144.9617136, -37.8452758],
          [144.9610374, -37.844401],
          [144.9604625, -37.8434874],
          [144.95999470000001, -37.842682],
          [144.959685, -37.842154],
          [144.960093699999987, -37.8417786],
          [144.9613836, -37.8406625],
          [144.9614882, -37.8406334],
          [144.9618503, -37.8403835],
          [144.962959399999988, -37.8396311],
          [144.963451600000013, -37.8394218],
          [144.9636796, -37.8392947],
          [144.9640015, -37.839138],
          [144.9643984, -37.8389579],
          [144.9647766, -37.8387715],
          [144.965128, -37.8385957],
          [144.9656564, -37.8383585],
          [144.9660117, -37.8382228],
          [144.966848199999987, -37.8379411],
          [144.9672905, -37.8377728],
          [144.9675728, -37.8377039],
          [144.9679906, -37.8375509],
          [144.9684492, -37.8373761],
          [144.9689421, -37.8371563]
        ]
      ]
    ]
  }
}
