{
  "type": "FeatureCollection",
  "id": "gates",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": "gate5",
        "style": "gate",
        "label": "Gate 5"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.969619, -37.836828],
            [144.969449, -37.836922],
            [144.969745, -37.837234],
            [144.969919, -37.837141],
            [144.969619, -37.836828]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gate8",
        "style": "gate",
        "label": "Gate 8"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.976684, -37.846443],
            [144.976538, -37.845992],
            [144.976304, -37.846043],
            [144.976454, -37.846496],
            [144.976684, -37.846443]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gate9",
        "style": "gate",
        "label": "Gate 9"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.977762, -37.849683],
            [144.977638, -37.849238],
            [144.977441, -37.849273],
            [144.977575, -37.849721],
            [144.977762, -37.849683]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gate10",
        "style": "gate",
        "label": "Gate 10"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.979193, -37.857046],
            [144.978932, -37.856698],
            [144.978783, -37.856772],
            [144.979062, -37.85712],
            [144.979193, -37.857046]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gate1",
        "style": "gate",
        "label": "Gate 1"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.965547813464923, -37.849309435462324],
            [144.96518822871036, -37.848984067330541],
            [144.965066649011021, -37.849063892356597],
            [144.965424183112674, -37.849397648140553],
            [144.965547813464923, -37.849309435462324]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gate2",
        "style": "gate",
        "label": "Gate 2"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.961890113794482, -37.845219472316316],
            [144.961582996194863, -37.844823826810057],
            [144.961422047654906, -37.844897704141012],
            [144.961733029713088, -37.845297992276762],
            [144.961890113794482, -37.845219472316316]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "gate3",
        "style": "gate",
        "label": "Gate 3"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.961361895170228, -37.840684243832555],
            [144.961237917734707, -37.840792216627747],
            [144.961521365762138, -37.841007430982572],
            [144.961646104575465, -37.840907988933395],
            [144.961361895170228, -37.840684243832555]
          ]
        ]
      }
    }
  ]
}
