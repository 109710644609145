{
  "type": "Feature",
  "properties": {
    "id": "lake",
    "label": "Albert Park Lake",
    "style": "lake"
  },
  "geometry": {
    "type": "MultiPolygon",
    "coordinates": [
      [
        [
          [144.967237, -37.839543],
          [144.967226399999987, -37.8394401],
          [144.967230699999988, -37.8393631],
          [144.967241300000012, -37.8393011],
          [144.967240299999986, -37.8392389],
          [144.9672921, -37.8391697],
          [144.9673388, -37.8391011],
          [144.9673896, -37.8390484],
          [144.967467, -37.8389907],
          [144.9675581, -37.8389371],
          [144.9676651, -37.838881],
          [144.967766899999987, -37.8388358],
          [144.967874599999988, -37.838798],
          [144.9679859, -37.8387729],
          [144.968112, -37.8387495],
          [144.968342299999989, -37.8387178],
          [144.968568, -37.8386894],
          [144.9686924, -37.8386731],
          [144.9688435, -37.8386618],
          [144.968978099999987, -37.8386567],
          [144.9692498, -37.8386572],
          [144.9693897, -37.8386655],
          [144.9695624, -37.8386823],
          [144.969724500000012, -37.8387032],
          [144.96991, -37.838735],
          [144.9700933, -37.8387886],
          [144.9702777, -37.8388555],
          [144.9703413, -37.8388882],
          [144.9704133, -37.8389384],
          [144.9704758, -37.8389894],
          [144.970542599999987, -37.839048],
          [144.9705861, -37.8391032],
          [144.9706253, -37.8391576],
          [144.9706439, -37.8391715],
          [144.9707248, -37.8392218],
          [144.9707578, -37.8392407],
          [144.970835, -37.8393094],
          [144.9709121, -37.8393845],
          [144.9709736, -37.8394484],
          [144.97104250000001, -37.8395461],
          [144.971101, -37.8396561],
          [144.971156799999989, -37.8397767],
          [144.971206599999988, -37.8399039],
          [144.971221500000013, -37.8399742],
          [144.9712395, -37.8400629],
          [144.971246900000011, -37.8401424],
          [144.971241600000013, -37.8401667],
          [144.9712384, -37.8401985],
          [144.971246400000012, -37.8403328],
          [144.9712434, -37.8404914],
          [144.971234399999986, -37.8405873],
          [144.9711776, -37.8410057],
          [144.971107100000012, -37.8414211],
          [144.9709648, -37.8419702],
          [144.9708554, -37.8423513],
          [144.970686, -37.8429312],
          [144.9705721, -37.8433975],
          [144.9703338, -37.8443868],
          [144.9703034, -37.8445287],
          [144.9702901, -37.8447124],
          [144.9702854, -37.8448707],
          [144.970300400000013, -37.8450494],
          [144.9703198, -37.8452056],
          [144.970358800000014, -37.8453772],
          [144.9703948, -37.845531],
          [144.9704427, -37.845692],
          [144.970508700000011, -37.8458553],
          [144.970589600000011, -37.8460304],
          [144.970708800000011, -37.8462383],
          [144.9708592, -37.84645],
          [144.9710452, -37.8466814],
          [144.9711537, -37.8468038],
          [144.9712932, -37.8469348],
          [144.971581500000013, -37.8471808],
          [144.9717722, -37.8473191],
          [144.972010899999987, -37.8474782],
          [144.9730573, -37.8482066],
          [144.9732665, -37.8483437],
          [144.9734556, -37.8484758],
          [144.9736277, -37.8486007],
          [144.973761, -37.8486888],
          [144.9739021, -37.8487549],
          [144.974069500000013, -37.8488271],
          [144.974232300000011, -37.8488786],
          [144.9744741, -37.8489385],
          [144.9746973, -37.8489748],
          [144.9752981, -37.8490426],
          [144.975554499999987, -37.8490781],
          [144.9757622, -37.8491148],
          [144.9759498, -37.8491809],
          [144.9761157, -37.8492556],
          [144.9763048, -37.8493572],
          [144.976394699999986, -37.8494098],
          [144.9764645, -37.84946],
          [144.9765482, -37.849542],
          [144.976636500000012, -37.8496497],
          [144.9766908, -37.8497354],
          [144.9768244, -37.850024],
          [144.9768401, -37.8500578],
          [144.9768973, -37.8501818],
          [144.9769594, -37.850315],
          [144.9770585, -37.8505303],
          [144.9770763, -37.8505665],
          [144.977217700000011, -37.8508717],
          [144.9772767, -37.8510036],
          [144.9775294, -37.8515555],
          [144.9775728, -37.851651],
          [144.9776472, -37.8517525],
          [144.9778162, -37.851968],
          [144.9779092, -37.8520818],
          [144.9780471, -37.8522201],
          [144.9780967, -37.852285],
          [144.9781386, -37.8523523],
          [144.9781634, -37.8524098],
          [144.9781743, -37.8524527],
          [144.9781758, -37.8525188],
          [144.9781743, -37.8525702],
          [144.9781572, -37.8526387],
          [144.9781262, -37.8526926],
          [144.9780843, -37.8527384],
          [144.9779907, -37.8528013],
          [144.9778952, -37.8528553],
          [144.9776162, -37.8529875],
          [144.977352700000012, -37.8531001],
          [144.9772767, -37.8531295],
          [144.9772333, -37.8531001],
          [144.9772054, -37.8531112],
          [144.977145, -37.8531234],
          [144.9770613, -37.8531283],
          [144.9769791, -37.8531173],
          [144.9769372, -37.8531075],
          [144.97687830000001, -37.8531271],
          [144.9767714, -37.8530879],
          [144.976652, -37.853034],
          [144.9765156, -37.8529508],
          [144.9760986, -37.852673],
          [144.97582890000001, -37.8524722],
          [144.975739, -37.8523964],
          [144.975605699999988, -37.8522544],
          [144.9754414, -37.8520622],
          [144.975281700000011, -37.8518835],
          [144.974888600000014, -37.8514601],
          [144.9745115, -37.8510602],
          [144.9743296, -37.8508646],
          [144.9740775, -37.8506049],
          [144.973689400000012, -37.8502259],
          [144.9732115, -37.849781],
          [144.9729024, -37.8494884],
          [144.972521, -37.8491492],
          [144.9721461, -37.8488341],
          [144.9718085, -37.8485502],
          [144.971481899999986, -37.8482646],
          [144.9711048, -37.8479703],
          [144.9709163, -37.8478041],
          [144.970548, -37.8475202],
          [144.970195, -37.8472554],
          [144.9688644, -37.8462652],
          [144.9682659, -37.8458168],
          [144.968119, -37.845706],
          [144.967980899999986, -37.8456177],
          [144.9678371, -37.8455457],
          [144.967665, -37.8454784],
          [144.9676696, -37.8454331],
          [144.967612300000013, -37.8453915],
          [144.967494499999987, -37.8453242],
          [144.9672908, -37.8452391],
          [144.967241199999989, -37.8452268],
          [144.967200100000014, -37.8452287],
          [144.967153700000011, -37.8452388],
          [144.9671126, -37.8452539],
          [144.9660236, -37.8448095],
          [144.9645571, -37.8441913],
          [144.9644347, -37.8441362],
          [144.964152500000012, -37.843993],
          [144.9635557, -37.8437041],
          [144.9633728, -37.8436037],
          [144.9633217, -37.8435633],
          [144.963278300000013, -37.843507],
          [144.9632349, -37.8434434],
          [144.9631977, -37.8433405],
          [144.9631853, -37.8432487],
          [144.9631915, -37.8431765],
          [144.963213200000013, -37.8431055],
          [144.9632411, -37.8430382],
          [144.963292200000012, -37.8429476],
          [144.963375899999988, -37.8428227],
          [144.963467400000013, -37.8427089],
          [144.9635635, -37.8426097],
          [144.9636705, -37.8425179],
          [144.9637712, -37.8424457],
          [144.963884400000012, -37.8423649],
          [144.9639216, -37.842338],
          [144.9640872, -37.8422498],
          [144.9641061, -37.8422415],
          [144.964221, -37.8421924],
          [144.964340099999987, -37.8421499],
          [144.9643797, -37.8421377],
          [144.9644361, -37.842121],
          [144.9645089, -37.8421032],
          [144.964584800000011, -37.8420878],
          [144.964662299999986, -37.8420758],
          [144.9647421, -37.8420691],
          [144.966203400000012, -37.8421152],
          [144.966315400000013, -37.842116],
          [144.966426, -37.8421143],
          [144.96653520000001, -37.8421083],
          [144.9666425, -37.8421001],
          [144.9668599, -37.8420786],
          [144.9669666, -37.8420622],
          [144.9670719, -37.8420406],
          [144.9670491, -37.8419705],
          [144.967083699999989, -37.8419653],
          [144.9671162, -37.8419605],
          [144.9671265, -37.842028],
          [144.9672088, -37.8420065],
          [144.9672971, -37.8419792],
          [144.9673372, -37.8419639],
          [144.9673565, -37.8419531],
          [144.967373400000014, -37.8419423],
          [144.967511300000012, -37.8419324],
          [144.9674962, -37.8418112],
          [144.9675327, -37.8417095],
          [144.9674972, -37.8414083],
          [144.967489699999987, -37.841407],
          [144.967405500000012, -37.8407471],
          [144.9674252, -37.8407457],
          [144.9674225, -37.840723],
          [144.967449, -37.840718],
          [144.96741320000001, -37.8404612],
          [144.9674075, -37.8404207],
          [144.967384, -37.8402517],
          [144.9673783, -37.8402112],
          [144.9673324, -37.8398819],
          [144.9673268, -37.8398415],
          [144.9672847, -37.8395396],
          [144.967237, -37.839543]
        ],
        [
          [144.9641607, -37.8434608],
          [144.964153, -37.8434273],
          [144.964156800000012, -37.8433878],
          [144.9641632, -37.8433473],
          [144.964156800000012, -37.8432804],
          [144.964144, -37.8432379],
          [144.964094, -37.8431527],
          [144.9640465, -37.843094],
          [144.9640118, -37.8430616],
          [144.963956700000011, -37.8430312],
          [144.9639028, -37.8430119],
          [144.963852800000012, -37.8429987],
          [144.963756499999988, -37.8429906],
          [144.9636898, -37.8429927],
          [144.9636231, -37.8430129],
          [144.963556399999987, -37.8430595],
          [144.9635102, -37.8431061],
          [144.9634768, -37.843175],
          [144.9634537, -37.8432541],
          [144.9634512, -37.8433138],
          [144.9634743, -37.8433696],
          [144.963514, -37.8434081],
          [144.9636137, -37.8434942],
          [144.9636796, -37.8435388],
          [144.9637424, -37.8435671],
          [144.96381550000001, -37.8435945],
          [144.963884799999988, -37.8436107],
          [144.963924600000013, -37.843632],
          [144.96399, -37.8436411],
          [144.9640311, -37.8436289],
          [144.9640632, -37.8436289],
          [144.964085, -37.8436137],
          [144.9641145, -37.8435955],
          [144.964144, -37.8435651],
          [144.964149099999986, -37.8435347],
          [144.9641466, -37.8435094],
          [144.96416450000001, -37.8434851],
          [144.9641607, -37.8434608]
        ],
        [
          [144.9646363, -37.8439147],
          [144.9646658, -37.8439218],
          [144.9646966, -37.8439158],
          [144.9647005, -37.8439056],
          [144.9647082, -37.8438935],
          [144.964712, -37.8438813],
          [144.9647095, -37.8438641],
          [144.96471840000001, -37.8438337],
          [144.9647095, -37.8437922],
          [144.9647146, -37.8437597],
          [144.9647133, -37.8437141],
          [144.964715899999987, -37.8436868],
          [144.964710700000012, -37.8436665],
          [144.9646966, -37.8436473],
          [144.964699200000013, -37.8436199],
          [144.9646812, -37.8435682],
          [144.9646593, -37.8435402],
          [144.9646363, -37.8435267],
          [144.9646107, -37.8435206],
          [144.964570900000012, -37.8435196],
          [144.9645298, -37.8435328],
          [144.9645016, -37.8435551],
          [144.964476, -37.8435946],
          [144.9644644, -37.8436351],
          [144.9644439, -37.8436767],
          [144.9644323, -37.8437334],
          [144.9644452, -37.84378],
          [144.9644798, -37.8438296],
          [144.964510600000011, -37.8438641],
          [144.9645247, -37.8438752],
          [144.964535, -37.8439016],
          [144.964563199999986, -37.8439198],
          [144.9645889, -37.8439239],
          [144.9646043, -37.8439178],
          [144.9646363, -37.8439147]
        ],
        [
          [144.9691406, -37.841612],
          [144.96898920000001, -37.8416179],
          [144.9689129, -37.841633],
          [144.968850300000014, -37.8416641],
          [144.968809, -37.8416993],
          [144.9687772, -37.8417395],
          [144.968761300000011, -37.8417922],
          [144.9687666, -37.8418407],
          [144.9687666, -37.8418817],
          [144.9687443, -37.8419805],
          [144.9687475, -37.8420549],
          [144.968730599999986, -37.8420968],
          [144.9687126, -37.8421637],
          [144.9687051, -37.8423127],
          [144.9687073, -37.8423746],
          [144.968711500000012, -37.8424725],
          [144.968726299999986, -37.8425094],
          [144.968721, -37.8425545],
          [144.9687136, -37.8425989],
          [144.968730599999986, -37.8426893],
          [144.9687549, -37.8427294],
          [144.968785700000012, -37.8427453],
          [144.96880440000001, -37.842785],
          [144.9688055, -37.8428034],
          [144.968779, -37.8428369],
          [144.968774700000012, -37.8428737],
          [144.9687903, -37.8429216],
          [144.9688319, -37.8429616],
          [144.968868, -37.8430135],
          [144.9688955, -37.8430402],
          [144.9689464, -37.843062],
          [144.9690163, -37.8430905],
          [144.9690903, -37.8431081],
          [144.969156199999986, -37.8431047],
          [144.969215600000013, -37.8430888],
          [144.96927070000001, -37.8430679],
          [144.9693289, -37.8430285],
          [144.9693671, -37.8429666],
          [144.969402300000013, -37.8428782],
          [144.969450200000011, -37.8427279],
          [144.969450200000011, -37.8426758],
          [144.9694877, -37.8425125],
          [144.969504199999989, -37.8424296],
          [144.9695034, -37.8422999],
          [144.969546799999989, -37.842038],
          [144.9695532, -37.8419309],
          [144.9695532, -37.8418322],
          [144.9695489, -37.8417945],
          [144.9695447, -37.8417719],
          [144.969512900000012, -37.8417275],
          [144.969507599999986, -37.8417049],
          [144.969479, -37.8416807],
          [144.9694228, -37.8416531],
          [144.969368800000012, -37.8416413],
          [144.969255800000013, -37.8416204],
          [144.9691406, -37.841612]
        ]
      ]
    ]
  }
}
