{
  "type": "FeatureCollection",
  "id": "grandstands",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "label": "Button Grandstand",
        "id": "gsbutton",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.970261, -37.838456],
            [144.970153, -37.838607],
            [144.970916, -37.83897],
            [144.971025, -37.83881],
            [144.970261, -37.838456]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Clark Grandstand",
        "id": "gsclark",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.971891, -37.841373],
            [144.971905, -37.841892],
            [144.972105, -37.841893],
            [144.972076, -37.841372],
            [144.971891, -37.841373]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Waite Grandstand",
        "id": "gswaite",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.974003, -37.848017],
            [144.974999, -37.847942],
            [144.974988, -37.84784],
            [144.97398, -37.847915],
            [144.974003, -37.848017]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Mercedes-AMG Grandstand",
        "id": "gsmercedes",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.97531, -37.847889],
            [144.975655, -37.84821],
            [144.975729, -37.848158],
            [144.97538, -37.847833],
            [144.97531, -37.847889]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Vettel Grandstand",
        "id": "gsvettel",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.978405, -37.852342],
            [144.978617, -37.852984],
            [144.978834, -37.852946],
            [144.978617, -37.852302],
            [144.978405, -37.852342]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Webber Grandstand",
        "id": "gswebber",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.978381, -37.852855],
            [144.978263, -37.852771],
            [144.977852, -37.853153],
            [144.977993, -37.853245],
            [144.978381, -37.852855]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Lauda Grandstand",
        "id": "gslauda",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.974898, -37.854198],
            [144.974858, -37.854395],
            [144.97558, -37.854494],
            [144.975617, -37.854295],
            [144.974898, -37.854198]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Senna Grandstand",
        "id": "gssenna",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.971508, -37.8523],
            [144.971287, -37.852495],
            [144.972154, -37.853207],
            [144.972267, -37.853257],
            [144.972459, -37.853296],
            [144.972993, -37.853296],
            [144.973281, -37.853272],
            [144.973601, -37.853141],
            [144.973481, -37.852885],
            [144.973247, -37.852982],
            [144.972956, -37.853016],
            [144.972686, -37.853024],
            [144.972469, -37.853012],
            [144.972385, -37.852989],
            [144.971508, -37.8523]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Fangio Grandstand",
        "id": "gsfangio",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.968165, -37.849962],
            [144.970735, -37.852066],
            [144.970983, -37.851856],
            [144.9684, -37.84977],
            [144.968165, -37.849962]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Brabham Grandstand",
        "id": "gsbrabham",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.966356, -37.846734],
            [144.96653, -37.847963],
            [144.967036, -37.847921],
            [144.966846, -37.846691],
            [144.966356, -37.846734]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Jones Grandstand",
        "id": "gsjones",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.966042, -37.847928],
            [144.965299, -37.847321],
            [144.965047, -37.847507],
            [144.965799, -37.84812],
            [144.966042, -37.847928]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Ricciardo Grandstand",
        "id": "gsricciardo",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.962421, -37.842232],
            [144.962455, -37.842387],
            [144.963792, -37.842199],
            [144.963749, -37.842041],
            [144.962421, -37.842232]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Hill Grandstand",
        "id": "gshill",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.961984, -37.841741],
            [144.962631, -37.841772],
            [144.962633, -37.841586],
            [144.961991, -37.841553],
            [144.961984, -37.841741]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Stewart Grandstand",
        "id": "gsstewart",
        "style": "grandstand"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.963937, -37.839558],
            [144.964687, -37.839194],
            [144.96456, -37.83904],
            [144.963812, -37.8394],
            [144.963937, -37.839558]
          ]
        ]
      }
    }
  ]
}
