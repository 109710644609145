import { Loader } from "@googlemaps/js-api-loader";

export default class GoogleMaps {
  constructor({ apiKey, containerId, styles, center, zoom }) {
    if (!apiKey) throw new Error("Please provide a Google Maps API Key");

    this.apiKey = apiKey;
    this.containerId = containerId || "map";
    this.styles = styles || {};
    this.center = center;
    this.zoom = zoom;

    this.loader = new Loader({
      apiKey: this.apiKey,
      version: "weekly",
    });
  }

  async init() {
    await this.loader.load();
    const { Map } = await google.maps.importLibrary("maps");

    this.map = new Map(document.getElementById(this.containerId), {
      center: this.center || undefined,
      zoom: this.zoom || undefined,
    });
  }

  addFeatures(features) {
    const { map } = this;

    // The Google Maps JS API doesn't allow us to set styles for GeoJSON objects as we add them.
    // Instead, we iteratively add all GeoJSON features, then we iterate again using `setStyle`.
    features.forEach((f) => {
      const label = f.id || f.properties?.id;
      console.debug(`Adding ${f.type} "${label}" to Google Maps`, f);
      map.data.addGeoJson(f);
    });

    map.data.setStyle((feature) => {
      const featureId = feature.getProperty("id");
      const styleName = feature.getProperty("style");
      const geometryType = feature.getGeometry().getType();

      console.debug(
        `setStyle for ${geometryType} feature "${featureId}" to "${styleName}"`
      );

      const styleOptions = this.styles[styleName] || {};
      const parsedOptions = { ...styleOptions };

      if (geometryType === "Point") {
        parsedOptions.label = feature.getProperty("label");
        parsedOptions.icon = {
          fillColor: styleOptions.markerColor,
          fillOpacity: styleOptions.markerOpacity,
          scale: styleOptions.markerSize,
        };

        if (styleOptions.markerSymbol === "circle") {
          parsedOptions.icon.path = google.maps.SymbolPath.CIRCLE;
          parsedOptions.icon.strokeColor = styleOptions.markerColor;
          parsedOptions.icon.strokeWeight = 0;
        }

        delete parsedOptions.markerColor;
        delete parsedOptions.markerSymbol;
        delete parsedOptions.markerSize;
      }

      return parsedOptions;
    });
  }

  styleFeatures(stylingFn) {
    this.map.data.setStyle(stylingFn);
  }
}
