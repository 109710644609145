{
  "type": "Feature",
  "properties": {
    "id": "racetrack",
    "label": "Racetrack",
    "style": "racetrack"
  },
  "geometry": {
    "type": "MultiLineString",
    "coordinates": [
      [
        [144.967630899999989, -37.8489709],
        [144.9672635, -37.8486737],
        [144.96701250000001, -37.8484708],
        [144.966669700000011, -37.8481935],
        [144.966081800000012, -37.8477195],
        [144.966036, -37.8476793],
        [144.9660156, -37.8476289],
        [144.9660003, -37.8475826],
        [144.96601050000001, -37.8475283],
        [144.966041100000012, -37.8474739],
        [144.9660767, -37.8474095],
        [144.966122500000012, -37.8473491],
        [144.9661653, -37.847275],
        [144.966188499999987, -37.8472178],
        [144.966218700000013, -37.8471317],
        [144.9662392, -37.8470326],
        [144.966249299999987, -37.8469099],
        [144.9662475, -37.8468153],
        [144.9662348, -37.8467073],
        [144.966218199999986, -37.8466207],
        [144.9662084, -37.8465731],
        [144.9661956, -37.8465349],
        [144.966169900000011, -37.8464806],
        [144.9661381, -37.8464217],
        [144.9661007, -37.8463599],
        [144.9660155, -37.8462489],
        [144.9659537, -37.8461872],
        [144.9658877, -37.8461324],
        [144.9658102, -37.8460675],
        [144.9657387, -37.8460129],
        [144.965550400000012, -37.8458711],
        [144.9650006, -37.8454687],
        [144.9649509, -37.84543],
        [144.9645955, -37.8451404],
        [144.9641173, -37.8447223],
        [144.963563599999986, -37.8441805],
        [144.9634211, -37.8440361],
        [144.9632444, -37.8438424],
        [144.9630889, -37.8436579],
        [144.9630152, -37.8435626],
        [144.96285, -37.8433489],
        [144.9623032, -37.8425756],
        [144.962144, -37.8423148],
        [144.962136, -37.8422852],
        [144.9621333, -37.8422555],
        [144.9621392, -37.8422157],
        [144.9621521, -37.8421835],
        [144.962176199999988, -37.8421475],
        [144.9622111, -37.8421178],
        [144.962254, -37.8420988],
        [144.9623188, -37.842082],
        [144.9624014, -37.8420687],
        [144.9625609, -37.8420508],
        [144.962681, -37.842032],
        [144.9627801, -37.8420034],
        [144.9629024, -37.8419873],
        [144.9630962, -37.8419617],
        [144.9633714, -37.841923],
        [144.9634911, -37.8418993],
        [144.9635653, -37.8418787],
        [144.963627699999989, -37.8418457],
        [144.9636783, -37.841801],
        [144.963709, -37.8417534],
        [144.96372980000001, -37.8417144],
        [144.96374990000001, -37.841662],
        [144.963758, -37.841617],
        [144.963760400000012, -37.8415735],
        [144.9636942, -37.8402557],
        [144.9636937, -37.8402332],
        [144.9636923, -37.8401939],
        [144.9636816, -37.839979],
        [144.9636769, -37.8399218],
        [144.963676872513332, -37.839920484879173],
        [144.963676874074906, -37.839919169579765],
        [144.963676904684235, -37.839917854501905],
        [144.963676964332024, -37.83991654004565],
        [144.963677053000083, -37.83991522661087],
        [144.96367717066147, -37.839913914597119],
        [144.963677317280343, -37.839912604403516],
        [144.963677492812195, -37.839911296428646],
        [144.963677697203536, -37.8399099910704],
        [144.963677930392208, -37.839908688725885],
        [144.963678192307327, -37.839907389791264],
        [144.96367848286917, -37.839906094661707],
        [144.963678801989346, -37.839904803731187],
        [144.963679149570794, -37.839903517392429],
        [144.963679525507729, -37.839902236036743],
        [144.963679929685867, -37.839900960053917],
        [144.963680361982171, -37.839899689832137],
        [144.963680822265218, -37.839898425757802],
        [144.963681310394918, -37.839897168215437],
        [144.963681826222796, -37.839895917587633],
        [144.963682369591965, -37.839894674254808],
        [144.963682940337094, -37.839893438595226],
        [144.963683538284585, -37.839892210984758],
        [144.963684163252481, -37.839890991796864],
        [144.963684815050726, -37.839889781402427],
        [144.963685493480995, -37.839888580169664],
        [144.963686198336944, -37.839887388464],
        [144.963686929404076, -37.839886206647968],
        [144.963687686460105, -37.839885035081068],
        [144.963688469274615, -37.839883874119721],
        [144.963689277609575, -37.839882724117082],
        [144.963690111218995, -37.839881585423015],
        [144.963690969849324, -37.839880458383902],
        [144.963691853239339, -37.839879343342602],
        [144.963692761120342, -37.839878240638321],
        [144.963693693216129, -37.839877150606512],
        [144.963694649243138, -37.839876073578765],
        [144.963695628910529, -37.839875009882732],
        [144.963696631920328, -37.839873959841988],
        [144.963697657967373, -37.839872923775978],
        [144.963756100000012, -37.8398208],
        [144.9638161, -37.8397636],
        [144.963834, -37.8397476],
        [144.9638617, -37.8397229],
        [144.9639301, -37.8396771],
        [144.963969600000013, -37.8396524],
        [144.9641121, -37.8395719],
        [144.964922800000011, -37.8391078],
        [144.965123299999988, -37.8389971],
        [144.9652259, -37.8389479],
        [144.9653439, -37.8388933],
        [144.9655315, -37.8388235],
        [144.9657207, -37.8387594],
        [144.9657694, -37.8387462],
        [144.965920399999987, -37.8387037],
        [144.9659743, -37.8386866],
        [144.966009899999989, -37.8386728],
        [144.966558099999986, -37.8384959],
        [144.9667039, -37.8384491],
        [144.9668796, -37.8383915],
        [144.9670475, -37.8383327],
        [144.967238900000012, -37.8382535],
        [144.9673807, -37.8381915],
        [144.9675862, -37.8380925],
        [144.9677048, -37.8380353],
        [144.967789, -37.8379966],
        [144.967898226068513, -37.83794625596969],
        [144.967898956603761, -37.837945922849315],
        [144.967899694363439, -37.837945599811832],
        [144.967900439124378, -37.83794528695492],
        [144.967901190661422, -37.837944984373173],
        [144.967901948747226, -37.837944692158118],
        [144.967902713152597, -37.837944410398109],
        [144.967903483646381, -37.837944139178354],
        [144.967904259995549, -37.83794387858088],
        [144.967905041965309, -37.837943628684485],
        [144.967905829319221, -37.837943389564742],
        [144.967906621819168, -37.837943161293964],
        [144.967907419225497, -37.837942943941187],
        [144.96790822129708, -37.837942737572121],
        [144.967909027791336, -37.837942542249188],
        [144.967909838464436, -37.837942358031462],
        [144.967910653071129, -37.837942184974636],
        [144.967911471365142, -37.837942023131056],
        [144.967912293099033, -37.837941872549663],
        [144.967913118024228, -37.837941733275983],
        [144.967913945891326, -37.837941605352142],
        [144.967914776449931, -37.837941488816824],
        [144.967915609448937, -37.837941383705271],
        [144.967916444636387, -37.837941290049272],
        [144.967917281759725, -37.837941207877144],
        [144.967918120565855, -37.83794113721374],
        [144.967918960801029, -37.837941078080426],
        [144.967919802211156, -37.837941030495081],
        [144.967920644541891, -37.837940994472106],
        [144.967921487538376, -37.837940970022387],
        [144.967922330945783, -37.837940957153322],
        [144.967923174509025, -37.837940955868795],
        [144.96792401797299, -37.837940966169207],
        [144.967924861082622, -37.837940988051436],
        [144.967925703582949, -37.837941021508854],
        [144.9679265452192, -37.837941066531364],
        [144.967927385736857, -37.837941123105331],
        [144.967928224881774, -37.837941191213666],
        [144.967929062400145, -37.837941270835763],
        [144.967929898038676, -37.837941361947536],
        [144.967930731544726, -37.837941464521442],
        [144.967931562666195, -37.837941578526461],
        [144.967932391151777, -37.837941703928109],
        [144.967933216750907, -37.83794184068848],
        [144.967934039213901, -37.837941988766204],
        [144.967934858292125, -37.837942148116504],
        [144.967935673737799, -37.837942318691184],
        [144.967936485304335, -37.837942500438672],
        [144.967937292746342, -37.837942693304001],
        [144.967938095819619, -37.837942897228857],
        [144.967938894281332, -37.837943112151549],
        [144.967939687890009, -37.837943338007108],
        [144.967940476405687, -37.83794357472722],
        [144.96794125958985, -37.837943822240298],
        [144.967942037205688, -37.837944080471502],
        [144.967942809018041, -37.837944349342727],
        [144.967943574793566, -37.837944628772675],
        [144.967944334300597, -37.83794491867684],
        [144.967945087309488, -37.837945218967555],
        [144.967945833592552, -37.837945529554013],
        [144.967946572924063, -37.83794585034228],
        [144.967947305080457, -37.83794618123536],
        [144.967948029840358, -37.83794652213318],
        [144.967948746984547, -37.837946872932655],
        [144.967949456296196, -37.837947233527686],
        [144.96795015756075, -37.837947603809248],
        [144.967950850566211, -37.837947983665359],
        [144.967951535102969, -37.837948372981131],
        [144.967952210964, -37.83794877163885],
        [144.967952877944953, -37.83794917951797],
        [144.967953535844117, -37.837949596495115],
        [144.967954184462513, -37.837950022444197],
        [144.967954823604032, -37.837950457236417],
        [144.967955453075376, -37.837950900740289],
        [144.967956072686206, -37.837951352821683],
        [144.96795668224911, -37.837951813343899],
        [144.967957281579771, -37.837952282167663],
        [144.967957870496974, -37.8379527591512],
        [144.967958448822571, -37.837953244150277],
        [144.967959016381712, -37.837953737018218],
        [144.967959573002759, -37.83795423760597],
        [144.967960118517396, -37.837954745762161],
        [144.96796065276061, -37.837955261333121],
        [144.967961175570906, -37.837955784162936],
        [144.967961686790147, -37.837956314093496],
        [144.967962186263719, -37.837956850964545],
        [144.967962673840617, -37.837957394613731],
        [144.967963149373361, -37.837957944876656],
        [144.967963612718165, -37.837958501586918],
        [144.967964063734911, -37.837959064576154],
        [144.967964502287202, -37.837959633674103],
        [144.967964928242395, -37.837960208708694],
        [144.967965341471739, -37.837960789505999],
        [144.967965741850207, -37.837961375890423],
        [144.967966129256752, -37.837961967684599],
        [144.967966503574218, -37.837962564709585],
        [144.96796686468943, -37.837963166784832],
        [144.967989, -37.837996],
        [144.9681, -37.838113],
        [144.968296, -37.838254],
        [144.968594, -37.838401],
        [144.968839, -37.838477],
        [144.969097, -37.838523],
        [144.969493, -37.838543],
        [144.96979, -37.838581],
        [144.970078, -37.838649],
        [144.970439, -37.838787],
        [144.970763, -37.838975],
        [144.971039, -37.839206],
        [144.971155, -37.839335],
        [144.971287, -37.839518],
        [144.97143, -37.839811],
        [144.971717, -37.8410246],
        [144.9717678, -37.8412392],
        [144.971776, -37.841274],
        [144.971788299999986, -37.8413881],
        [144.971797, -37.841469],
        [144.9717951, -37.8414889],
        [144.9717884, -37.8415582],
        [144.9717798, -37.8416468],
        [144.971778, -37.841665],
        [144.971751899999987, -37.8417476],
        [144.971726600000011, -37.8418277],
        [144.971718, -37.841855],
        [144.97162, -37.842035],
        [144.9716128, -37.8420443],
        [144.9715668, -37.8421036],
        [144.9715388, -37.8421398],
        [144.971527, -37.842155],
        [144.9714817, -37.8422023],
        [144.971433, -37.8422532],
        [144.971359400000011, -37.8423301],
        [144.971280299999989, -37.8424035],
        [144.9712106, -37.8424728],
        [144.971147, -37.842552],
        [144.9711351, -37.842571],
        [144.971068, -37.842666],
        [144.971044799999987, -37.8427147],
        [144.970981, -37.842849],
        [144.9709766, -37.8428673],
        [144.970929, -37.843066],
        [144.970893499999988, -37.8432146],
        [144.9708159, -37.8435386],
        [144.9707421, -37.8438469],
        [144.970646, -37.844248],
        [144.970599, -37.844682],
        [144.970625, -37.845116],
        [144.970722, -37.845544],
        [144.970842, -37.845857],
        [144.970943, -37.846059],
        [144.971195, -37.846445],
        [144.971509, -37.846802],
        [144.971866, -37.84711],
        [144.972911, -37.847843],
        [144.973116, -37.847954],
        [144.973341, -37.848038],
        [144.973662, -37.848101],
        [144.973828, -37.848111],
        [144.974494, -37.848106],
        [144.974644683850016, -37.848101993669481],
        [144.974729162152641, -37.8481094056093],
        [144.974825373552875, -37.848135347392784],
        [144.974896945448194, -37.848164068642426],
        [144.97497907713128, -37.848204834267875],
        [144.975109, -37.848272],
        [144.975244, -37.848346],
        [144.976852, -37.849422]
      ],
      [
        [144.9736519, -37.8523986],
        [144.9734287, -37.8525982],
        [144.9733395, -37.8526803],
        [144.973267099999987, -37.8527396],
        [144.973219199999988, -37.8527753],
        [144.973147399999988, -37.8528089],
        [144.9730744, -37.8528346],
        [144.972984, -37.8528622],
        [144.972872599999988, -37.8528787],
        [144.9727719, -37.8528805],
        [144.972674399999988, -37.8528684],
        [144.972567700000013, -37.8528431],
        [144.972480100000013, -37.852812],
        [144.9724103, -37.8527769],
        [144.9723306, -37.8527285],
        [144.9722698, -37.8526796],
        [144.971345899999989, -37.8519381],
        [144.968984299999988, -37.8500562],
        [144.9679562, -37.84923],
        [144.967630899999989, -37.8489709]
      ],
      [
        [144.9736519, -37.8523986],
        [144.9733221, -37.8526073],
        [144.9732023, -37.8526878],
        [144.9731157, -37.8527261],
        [144.973018200000013, -37.8527484],
        [144.9729619, -37.8527539],
        [144.9728991, -37.852757],
        [144.9728427, -37.8527587],
        [144.9727701, -37.8527552],
        [144.9726957, -37.8527436],
        [144.9726202, -37.8527226],
        [144.972532699999988, -37.8526941],
        [144.972440799999987, -37.8526484],
        [144.968602, -37.8495625],
        [144.9685294, -37.8495104],
        [144.9682967, -37.8493578],
        [144.9680531, -37.8492015],
        [144.967630899999989, -37.8489709]
      ],
      [
        [144.976851980451698, -37.849422033040284],
        [144.97696830000001, -37.8495092],
        [144.977041500000013, -37.8495751],
        [144.9771204, -37.8496494],
        [144.9771303, -37.8496586],
        [144.9771844, -37.8497086],
        [144.977234900000013, -37.8497513],
        [144.9773045, -37.8498298],
        [144.9773739, -37.8499165],
        [144.9774404, -37.850008],
        [144.97759, -37.8502672],
        [144.9776486, -37.8503872],
        [144.9777121, -37.8505422],
        [144.977773899999988, -37.8506995],
        [144.9778478, -37.8509006],
        [144.9779642, -37.851212],
        [144.978192299999989, -37.8518465],
        [144.978237299999989, -37.8519617],
        [144.9782883, -37.8520983],
        [144.9783553, -37.8522752],
        [144.978468, -37.8526204],
        [144.9785149, -37.8527697],
        [144.9785564, -37.8529031],
        [144.978586, -37.8530312],
        [144.97858674759496, -37.853033347105999],
        [144.978587447835395, -37.853035504142987],
        [144.978588100509995, -37.853037670459848],
        [144.978588705421714, -37.853039845402712],
        [144.97858926238797, -37.853042028315087],
        [144.978589771240678, -37.853044218538095],
        [144.978590231826217, -37.853046415410624],
        [144.978590644005578, -37.853048618269582],
        [144.97859100765433, -37.853050826450051],
        [144.978591322662766, -37.853053039285513],
        [144.978591588935728, -37.853055256108036],
        [144.97859180639287, -37.853057476248523],
        [144.978591974968566, -37.85305969903682],
        [144.97859209461194, -37.853061923802002],
        [144.978592165286841, -37.853064149872559],
        [144.978592186972008, -37.853066376576564],
        [144.97859215966082, -37.853068603241915],
        [144.978592083361548, -37.853070829196511],
        [144.97859195809724, -37.853073053768462],
        [144.978591783905671, -37.853075276286319],
        [144.978591560839448, -37.85307749607923],
        [144.978591288965902, -37.853079712477175],
        [144.978590968367087, -37.853081924811157],
        [144.97859059913975, -37.853084132413407],
        [144.97859018139539, -37.853086334617572],
        [144.978589715260057, -37.853088530758946],
        [144.978589200874438, -37.853090720174663],
        [144.978588638393859, -37.853092902203834],
        [144.978588027988025, -37.85309507618787],
        [144.978587369841222, -37.853097241470557],
        [144.978586664152118, -37.853099397398339],
        [144.978585911133706, -37.853101543320463],
        [144.978585111013217, -37.853103678589207],
        [144.978584264032264, -37.853105802560052],
        [144.978583370446415, -37.853107914591931],
        [144.978582430525421, -37.853110014047317],
        [144.978581444552987, -37.853112100292527],
        [144.978580412826744, -37.853114172697843],
        [144.978579335658083, -37.853116230637745],
        [144.978578213372117, -37.853118273491049],
        [144.978577046307635, -37.853120300641145],
        [144.978575834816866, -37.853122311476163],
        [144.978574579265512, -37.853124305389159],
        [144.978573280032577, -37.85312628177828],
        [144.978571937510168, -37.853128240046985],
        [144.978570552103548, -37.853130179604179],
        [144.978569124230802, -37.853132099864446],
        [144.978567654323058, -37.853134000248154],
        [144.978566142823922, -37.853135880181711],
        [144.978564590189592, -37.853137739097683],
        [144.978562996888797, -37.853139576434948],
        [144.978561363402349, -37.853141391638957],
        [144.97855969022342, -37.853143184161794],
        [144.978557977856951, -37.853144953462412],
        [144.978556226819848, -37.853146699006757],
        [144.978554437640668, -37.853148420267971],
        [144.978552610859367, -37.853150116726496],
        [144.978550747027413, -37.853151787870281],
        [144.978548846707355, -37.853153433194919],
        [144.978546910472829, -37.853155052203775],
        [144.978544938908215, -37.853156644408152],
        [144.978542932608661, -37.853158209327496],
        [144.978540892179666, -37.853159746489439],
        [144.978538818237183, -37.853161255430003],
        [144.97853671140723, -37.853162735693736],
        [144.978534572325657, -37.85316418683383],
        [144.978532401638176, -37.853165608412283],
        [144.9785302, -37.853167],
        [144.9770045, -37.8536507],
        [144.9762427, -37.8538867],
        [144.9761354, -37.8539126],
        [144.9759947, -37.8539251],
        [144.9759125, -37.8539254],
        [144.9758333, -37.8539151],
        [144.9757482, -37.8538876],
        [144.9756256, -37.8538389],
        [144.9755073, -37.8537743],
        [144.975412299999988, -37.8537004],
        [144.9753164, -37.8535857],
        [144.9746668, -37.8526416],
        [144.9743662, -37.8522554],
        [144.9742877, -37.8521588],
        [144.974241799999987, -37.8521125],
        [144.974203499999987, -37.8520839],
        [144.9741616, -37.8520666],
        [144.9741066, -37.8520606],
        [144.974063199999989, -37.852064],
        [144.974028800000013, -37.8520759],
        [144.973998, -37.8520931],
        [144.973941700000012, -37.8521395],
        [144.9736519, -37.8523986]
      ]
    ]
  }
}
