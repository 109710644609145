{
  "type": "FeatureCollection",
  "id": "viewingMounds",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "label": "Viewing Mound",
        "id": "vmound1",
        "style": "viewingMound"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.975219, -37.853105],
            [144.975229, -37.853187],
            [144.975285, -37.853281],
            [144.975417, -37.853451],
            [144.975666, -37.853599],
            [144.975927, -37.853687],
            [144.97616, -37.853724],
            [144.97634, -37.85373],
            [144.976423, -37.853677],
            [144.976412, -37.853605],
            [144.976324, -37.853504],
            [144.97616, -37.853358],
            [144.975929, -37.853205],
            [144.975684, -37.853122],
            [144.975496, -37.853096],
            [144.97532, -37.853078],
            [144.975219, -37.853105]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Viewing Mound",
        "id": "vmound2",
        "style": "viewingMound"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.965803, -37.845945],
            [144.965835, -37.84593],
            [144.965876, -37.845879],
            [144.965885, -37.845808],
            [144.965837, -37.845714],
            [144.965516, -37.845393],
            [144.965376, -37.845292],
            [144.965258, -37.845212],
            [144.964873, -37.844994],
            [144.964739, -37.844965],
            [144.964624, -37.844962],
            [144.964516, -37.844993],
            [144.964504, -37.845003],
            [144.964504, -37.845029],
            [144.965767, -37.845937],
            [144.965803, -37.845945]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Viewing Mound",
        "id": "vmound3",
        "style": "viewingMound"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.97025, -37.838449],
            [144.970007, -37.838424],
            [144.969617, -37.838466],
            [144.969601, -37.838511],
            [144.970152, -37.838622],
            [144.97025, -37.838449]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Viewing Mound",
        "id": "vmound4",
        "style": "viewingMound"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.971531, -37.839813],
            [144.97185, -37.84137],
            [144.972207, -37.841355],
            [144.972194, -37.840594],
            [144.972009, -37.840221],
            [144.97169, -37.839838],
            [144.971531, -37.839813]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Viewing Mound",
        "id": "vmound5",
        "style": "viewingMound"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.971836, -37.841954],
            [144.971115, -37.842762],
            [144.971359, -37.842896],
            [144.972076, -37.842085],
            [144.971836, -37.841954]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Viewing Mound",
        "id": "vmound6",
        "style": "viewingMound"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.971025, -37.843068],
            [144.970648, -37.844725],
            [144.97086, -37.84476],
            [144.971242, -37.843102],
            [144.971025, -37.843068]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "label": "Viewing Mound",
        "id": "vmound7",
        "style": "viewingMound"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [144.97237, -37.847406],
            [144.973345, -37.847987],
            [144.973778, -37.847932],
            [144.972541, -37.847218],
            [144.97237, -37.847406]
          ]
        ]
      }
    }
  ]
}
