import GoogleMaps from "./map-apis/googleMaps";
import mapStyles from "./map-data/styles.json";
import albertParkGeoJson from "./map-data/park.json";
import albertParkLakeGeoJson from "./map-data/lake.json";
import racetrackGeoJson from "./map-data/racetrack.json";
import gatesGeoJson from "./map-data/gates.json";
import grandstandsGeoJson from "./map-data/grandstands.json";
import overpassesGeoJson from "./map-data/pedestrianOverpasses.json";
import turnsGeoJson from "./map-data/turns.json";
import viewingMoundsGeoJson from "./map-data/viewingMounds.json";

let { GOOGLE_MAPS_API_KEY } = process.env;

const mapData = [
  albertParkGeoJson,
  albertParkLakeGeoJson,
  racetrackGeoJson,
  gatesGeoJson,
  grandstandsGeoJson,
  overpassesGeoJson,
  turnsGeoJson,
  viewingMoundsGeoJson,
];

async function run() {
  const gMaps = new GoogleMaps({
    apiKey: GOOGLE_MAPS_API_KEY,
    containerId: "map",
    styles: mapStyles,
    center: { lat: -37.84881, lng: 144.971118 },
    zoom: 15,
  });
  await gMaps.init();
  gMaps.addFeatures(mapData);
}

run();
