{
  "type": "FeatureCollection",
  "id": "turns",
  "features": [
    {
      "type": "Feature",
      "properties": { "label": "14", "id": "turn14", "style": "turn" },
      "geometry": {
        "type": "Point",
        "coordinates": [144.972771899999969, -37.8528805]
      }
    },
    {
      "type": "Feature",
      "properties": { "label": "13", "id": "turn13", "style": "turn" },
      "geometry": { "type": "Point", "coordinates": [144.9741066, -37.8520606] }
    },
    {
      "type": "Feature",
      "properties": { "label": "12", "id": "turn12", "style": "turn" },
      "geometry": {
        "type": "Point",
        "coordinates": [144.975748200000027, -37.8538876]
      }
    },
    {
      "type": "Feature",
      "properties": { "label": "11", "id": "turn11", "style": "turn" },
      "geometry": {
        "type": "Point",
        "coordinates": [144.978579335658083, -37.853116230637738]
      }
    },
    {
      "type": "Feature",
      "properties": { "label": "10", "id": "turn10", "style": "turn" },
      "geometry": {
        "type": "Point",
        "coordinates": [144.974825373552903, -37.848135347392777]
      }
    },
    {
      "type": "Feature",
      "properties": { "label": "9", "id": "turn9", "style": "turn" },
      "geometry": { "type": "Point", "coordinates": [144.973341, -37.848038] }
    },
    {
      "type": "Feature",
      "properties": { "label": "8", "id": "turn8", "style": "turn" },
      "geometry": { "type": "Point", "coordinates": [144.970763, -37.838975] }
    },
    {
      "type": "Feature",
      "properties": { "label": "7", "id": "turn7", "style": "turn" },
      "geometry": { "type": "Point", "coordinates": [144.968839, -37.838477] }
    },
    {
      "type": "Feature",
      "properties": { "label": "6", "id": "turn6", "style": "turn" },
      "geometry": {
        "type": "Point",
        "coordinates": [144.967927385736857, -37.837941123105338]
      }
    },
    {
      "type": "Feature",
      "properties": { "label": "5", "id": "turn5", "style": "turn" },
      "geometry": { "type": "Point", "coordinates": [144.963727, -37.839884] }
    },
    {
      "type": "Feature",
      "properties": { "label": "4", "id": "turn4", "style": "turn" },
      "geometry": { "type": "Point", "coordinates": [144.963709, -37.8417534] }
    },
    {
      "type": "Feature",
      "properties": { "label": "3", "id": "turn3", "style": "turn" },
      "geometry": {
        "type": "Point",
        "coordinates": [144.962139199999967, -37.8422157]
      }
    },
    {
      "type": "Feature",
      "properties": { "label": "2", "id": "turn2", "style": "turn" },
      "geometry": { "type": "Point", "coordinates": [144.9660155, -37.8462489] }
    },
    {
      "type": "Feature",
      "properties": { "label": "1", "id": "turn1", "style": "turn" },
      "geometry": {
        "type": "Point",
        "coordinates": [144.96601560000002, -37.847628899999989]
      }
    }
  ]
}
